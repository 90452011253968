import React from 'react';
import './HeroSection.css'; // Ensure this file contains relevant styles

import {useNavigate} from "react-router-dom";

const HeroSection = () => {
    //const [isVisible, setIsVisible] = useState(false);


    const navigate = useNavigate();
    const handleStartLocationSelection = () => {
        navigate('/location');
    };
    const handleButtonClick = () => {
        //setIsVisible(true);
        handleStartLocationSelection();
    };
    /*
    useEffect(() => {
        if (isVisible) {
            const section = document.getElementById('location-selection-section');
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [isVisible]); // This effect runs when `isVisible` changes
    */
    return (
        <div>
            <div className="hero-section d-flex align-items-center justify-content-center text-center">
                <div className="hero-content text-white">
                    <h1 className="display-4">Eremovals</h1>
                    <h2>Reliable and Affordable Van Removal Services</h2>

                    <button className="btn btn-custom btn-lg" onClick={handleButtonClick}>
                        Get an Instant Quote
                    </button>
                </div>
            </div>


        </div>
    );
};

export default HeroSection;
