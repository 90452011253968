// BookingResult.js
import React from 'react';
import '../components/BookingResult.css';
const BookingResult = () => {
    return (
        <div className="booking-result">
            <h1>Booking Confirmed!</h1>
            <p>Thank you for your booking.</p>
            <p>Your booking details have been confirmed. We will contact you shortly.</p>
        </div>
    );
};

export default BookingResult;
